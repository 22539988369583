// import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/Common/SEO/index';
import '@fortawesome/fontawesome-svg-core/styles.css';
import React, { useContext, useEffect } from 'react';
import Navigation from '@components/Navigation/index';
import FooterV2 from '@components/FooterV2/index';
import CrackedCrioCareersSection from '@components/v5/CrackedCrioCareersSection/index';
import ProjectsSection from '@components/v5/ProjectsSection/index';
import RoundedLayout from '@components/v5/layouts/RoundedLayout/index';
import VideoTestimonialSection from '@components/v5/VideoTestimonialSection/index';
import CrioComparisionSection from '@components/v5/CrioComparisionSection/index';
import Testimonial from '@components/v5/Testimonial/index';
import FAQSection from '@components/v5/FAQSection/index';
// import StepsSection from '@components/v5/StepsSection/index';
import WhyCrioSectionV2 from '@components/v5/WhyCrioSectionV2/index';
import WhyCrioSection from '@components/v5/WhyCrioSection/index';
import CrioButton from '@components/v5/CrioButton/index';
import PostContentCTALayout from '@components/v5/layouts/PostContentCTALayout/index';
import { Link } from 'gatsby';
import { ERoutes } from '@src/constants/routes/index';
import { EProgramIDs } from '@src/constants/data/programs/index';
import SaleMiddleStrip from '@components/v5/Sale/SaleMiddleStrip';
import { stripData } from '@components/Sale/data';
import FspStylePageHero from '@components/v5/FspStylePageHero/index';
import StatsPanelFSP from '@components/v5/StatsPanelFSP/index';
import FspCurriculumSectionV2 from '@components/v5/FspCurriculumSection-V2/index';
import FspCurriculumSection from '@components/v5/FspCurriculumSection/index';
import FspPricingCardSection from '@components/v5/FspPricingCardSection/index';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import {
  LEAD_GEN_HOME_PROJECTS,
  LEAD_GEN_FLOATING_CTA,
  LEAD_GEN_DEVELOPERS_CRACKED,
  LEAD_GEN_WHY_CRIO,
  LEAD_GEN_PROJECTS,
  // LEAD_GEN_PROGRAM_STEPS,
  LEAD_GEN_CAREER_ASSISTANCE,
  LEAD_GEN_TESTIMONIALS,
  LEAD_GEN_CRIO_COMPARISION,
  LEAD_GEN_FAQ,
  LEAD_GEN_CURRICULUM_PRICING_CARD,
  LEAD_GEN_PLAN_SECTION,
} from '../constants/LeadGenerationConstants/index';
import ScrollButtonComponent from '@components/ScrollButtonComponent/index';
import { useWebEngage } from '@src/hooks/useWebEngage';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { GTM } from '@src/analytics/gtm';
import PersonalizedPlan from '@components/v5/PersonalizedPlan/index';
import TechnologiesMasterSection from '@components/v5/TechnologiesMasterSection/index';
import fsp from '../seo/jsonld/fsd.json';
import fdt from '../seo/jsonld/fdt.json';
import bdt from '../seo/jsonld/bdt.json';
import dataScienceJsonLd from '../seo/jsonld/data-science.json';
import JobGuaranteeCard from '@components/v5/JobGuaranteeCard/index';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import useCrioStat from '@src/hooks/useCrioStats';
import FspCurriculumSectionV3 from '@components/v5/FspCurriculumSection-V3/index';
import WhyCrioSectionV3 from '@components/v5/WhyCrioSectionV3/index';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';

export default function FspStylePage(props) {
  const { captureUserClickEvent } = useWebEngage();
  const state = useContext(GlobalStateContext);

  const { isDataSciencePage } = useDataScienceProgram();

  const onPlacementButtonClick = () => {
    captureUserClickEvent('Get our Placement Stats', {
      placementLocation: 'FSP_PAGE',
    });
    GTM.leadSquaredEvent(
      ELeadSquaredActivityCode.SD_PAGE_GET_OUR_PLACEMENT_STATS_CLICKED,
      state.email,
    );
  };
  const { data: queryData } = props;

  const { fspStylePagesYaml: data } = queryData;
  const program = props.data.allStrapiPrograms.nodes.find(
    (ele) => ele.PrimaryProgramID == data.PrimaryProgramID,
  );

  const programId = program?.PrimaryProgramID;
  const jsonLd =
    programId === EProgramIDs.DATA_SCIENCE
      ? dataScienceJsonLd
      : programId === EProgramIDs.FULL_STACK
      ? fdt
      : programId === EProgramIDs.FELLOWSHIP
      ? fsp
      : bdt;

  const sale = props.data.strapiSale.SaleID;

  const { placementStats } = useCrioStat();

  const getProjectsSectionPreContent = (sale) => {
    switch (sale) {
      case 'true':
        return (
          <div className="mx-4 mb-10 flex justify-center">
            <SaleMiddleStrip data={stripData[program.PrimaryProgramID]}>
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_HOME_PROJECTS}
              />
            </SaleMiddleStrip>
          </div>
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    //webengage events specific to FSD Page(experiment):
    GTM.track(gtmEvents.PAGE_VIEW_FSD, {
      type: data.PrimaryProgramID,
      slug: data.slug,
    });

    //GA EVENT FOR PAGE VIEW:
    GTM.track(gtmEvents.PAGE_VIEW, {
      type: data.PrimaryProgramID,
      slug: data.slug,
    });
  }, []);

  return (
    <div>
      <SEO
        title={data.SEOTitle}
        description={data.SEODescription}
        canonicalUrl={data.canonicalUrl}
        meta={data.metatags || []}
      >
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </SEO>
      <ScrollButtonComponent
        component={
          <LeadGenerationButton
            type="Apply & Start Free"
            buttonLocation={LEAD_GEN_FLOATING_CTA}
          />
        }
        startAt={600}
      ></ScrollButtonComponent>
      <Navigation
        dark
        program={program.PrimaryProgramID}
        sale={sale}
        hideCTA={isDataSciencePage}
      ></Navigation>
      <div className="bg-v5-green-700">
        <div className="text-v5-neutral-300">
          <FspStylePageHero
            program={program}
            title={data.heroTitle}
            image={data.heroImage.childImageSharp}
            description={data.heroDescription}
            subTitle={data.heroSubtitle}
            stats={
              [
                // {
                //   title: 'Next Batch',
                //   subtitle: format(
                //     new Date(program.ProgramDate),
                //     'MMMM dd, yyyy',
                //   ),
                // },
              ]
            }
            statsSubtitle={data.statsSubtitle}
            sale={sale}
          ></FspStylePageHero>
        </div>
        {data.slug === '/fellowship-program-software-developer' ? (
          <WhyCrioSectionV2
            dark
            heading={data.highlightsTitle}
            description={data.highlightsDescription}
            quote={data.highlightsQuote}
            data={data.highlightsV2}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  text={
                    <div className="pr-2 text-left">
                      <h6 className="mb-1 font-manrope text-[18px] font-bold leading-[18px]">
                        Apply & Start for free
                      </h6>
                    </div>
                  }
                  iconClassName={`text-3xl`}
                  className={`h-[77px]`}
                  buttonLocation={LEAD_GEN_WHY_CRIO}
                />
              </PostContentCTALayout>
            }
          ></WhyCrioSectionV2>
        ) : data.slug === '/software-development-fellowship-program' ? (
          <WhyCrioSectionV3
            dark
            heading={data.highlightsTitle}
            description={data.highlightsDescription}
            quote={data.highlightsQuote}
            data={data.highlightsV2}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_WHY_CRIO}
                />
              </PostContentCTALayout>
            }
          ></WhyCrioSectionV3>
        ) : (
          <WhyCrioSection
            dark
            heading={data.highlightsTitle}
            description={data.highlightsDescription}
            quote={data.highlightsQuote}
            data={data.highlights}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  text={
                    <div className="pr-2 text-left">
                      <h6 className="mb-1 font-manrope text-[18px] font-bold leading-[18px]">
                        Apply & Start for free
                      </h6>
                    </div>
                  }
                  iconClassName={`text-3xl`}
                  className={`h-[77px]`}
                  buttonLocation={LEAD_GEN_WHY_CRIO}
                />
              </PostContentCTALayout>
            }
          ></WhyCrioSection>
        )}

        <CrackedCrioCareersSection
          dark
          postContent={
            <PostContentCTALayout>
              <div>
                <div className="-mb-10">
                  <StatsPanelFSP
                    stats={placementStats.map((stat) => {
                      return {
                        title: stat.content,
                        subtitle: `${stat.label}`,
                      };
                    })}
                    dark
                  ></StatsPanelFSP>
                </div>
                <PostContentCTALayout className="mb-10">
                  <Link to={ERoutes.PLACEMENTS}>
                    <CrioButton
                      variant="secondary"
                      onClick={onPlacementButtonClick}
                      className="h-[64px] w-[300px] border-white py-6 text-white sm:w-auto"
                    >
                      Get our Placement Stats
                    </CrioButton>
                  </Link>
                  <LeadGenerationButton
                    type="Apply & Start Free"
                    className="h-[64px] w-[300px]"
                    buttonLocation={LEAD_GEN_DEVELOPERS_CRACKED}
                  />
                </PostContentCTALayout>
              </div>
            </PostContentCTALayout>
          }
        ></CrackedCrioCareersSection>

        <RoundedLayout
          roundedBg="bg-v5-green-700"
          className="bg-white text-black"
          both
        >
          <div className="min-h-[800px] py-10">
            <ProjectsSection
              heading={data.projectsTitle}
              description={data.projectsDescriptionV2}
              projects={data.projects}
              postContent={
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Apply & Start Free"
                    buttonLocation={LEAD_GEN_PROJECTS}
                  />
                </PostContentCTALayout>
              }
              preContent={getProjectsSectionPreContent(sale)}
            ></ProjectsSection>
          </div>
        </RoundedLayout>
        {data.slug === '/fellowship-program-software-developer' ? (
          <div className="mt-10">
            <FspCurriculumSectionV2
              dark
              heading={data.curriculumTitle}
              description={data.curriculumDescription}
              quote={data.curriculumQuote}
              type={data.PrimaryProgramID}
              curriculum={data.curriculum}
              advancedCurriculum={data.advancedCurriculum}
              dreamCompanies={data.dreamCompanies}
              superDreamCompanies={data.superDreamCompanies}
              curriculumV2={data.curriculumfsd}
              slug={data.slug}
            ></FspCurriculumSectionV2>
          </div>
        ) : data.slug === '/software-development-fellowship-program' ? (
          <div className="mt-10">
            <FspCurriculumSectionV3
              dark
              heading={data.curriculumTitle}
              description={data.curriculumDescription}
              quote={data.curriculumQuote}
              type={data.PrimaryProgramID}
              curriculumV2={data.curriculumfsd}
            ></FspCurriculumSectionV3>
          </div>
        ) : (
          <div className="mt-10">
            <FspCurriculumSection
              dark
              heading={data.curriculumTitle}
              description={data.curriculumDescription}
              quote={data.curriculumQuote}
              type={data.PrimaryProgramID}
              curriculum={data.curriculum}
              advancedCurriculum={data.advancedCurriculum}
              dreamCompanies={data.dreamCompanies}
              superDreamCompanies={data.superDreamCompanies}
              curriculumV2={data.curriculumfsd}
              hideAdvanceCurriculumSection={isDataSciencePage ? true : false}
            ></FspCurriculumSection>
          </div>
        )}

        {data.technologies ? (
          <TechnologiesMasterSection
            data={data.technologies}
            postContent={
              <PostContentCTALayout>
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={'LEAD_GEN_TECHNOLOGIES'}
                />
              </PostContentCTALayout>
            }
          />
        ) : (
          <></>
        )}

        {!isDataSciencePage ? (
          <PersonalizedPlan
            postContent={
              <LeadGenerationButton
                type="Download"
                text="Apply & Start For Free"
                buttonLocation={LEAD_GEN_PLAN_SECTION}
                clickEvent={() => {
                  GTM.track(gtmEvents.APPLY_BUTTON_BELOW_CURRICULLUM_CLICKED, {
                    type: data.programId,
                    location: 'BELOW CURRICULUM',
                  });
                }}
              />
            }
          />
        ) : (
          <></>
        )}

        <WhyCrioSection
          dark
          heading={data.careerTitleV2}
          description={data.careerDescription}
          data={data.careers}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_CAREER_ASSISTANCE}
              />
            </PostContentCTALayout>
          }
        ></WhyCrioSection>

        <FspPricingCardSection
          dark
          price={data.price}
          duration={data.priceDuration}
          sale={sale}
          program={program.PrimaryProgramID}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_CURRICULUM_PRICING_CARD}
              />
            </PostContentCTALayout>
          }
        ></FspPricingCardSection>
        {!isDataSciencePage ? (
          <JobGuaranteeCard data={data.jobGuarantee} />
        ) : (
          <></>
        )}
        <VideoTestimonialSection
          dark
          quote={
            isDataSciencePage ? (
              <>
                Get more insights on how Crio can help you{' '}
                <br class="hidden sm:block" />
                crack a data analytics role in top tech companies.
              </>
            ) : (
              data.testimonialQuote
            )
          }
          disableCareerHandbook={
            data.PrimaryProgramID === EProgramIDs.QA_AUTOMATION_TESTING
          }
          postContent={
            <PostContentCTALayout>
              <div className="-mt-16 xxs:-mt-10 sm:-mt-0">
                <LeadGenerationButton
                  type="Apply & Start Free"
                  buttonLocation={LEAD_GEN_TESTIMONIALS}
                />
              </div>
            </PostContentCTALayout>
          }
        ></VideoTestimonialSection>
        <RoundedLayout roundedBg="bg-white" className="bg-v5-green-700" bottom>
          <CrioComparisionSection
            dark
            postContent={
              <div className="-mt-12">
                <PostContentCTALayout>
                  <LeadGenerationButton
                    type="Apply & Start Free"
                    buttonLocation={LEAD_GEN_CRIO_COMPARISION}
                  />
                </PostContentCTALayout>
                {!isDataSciencePage ? (
                  <div className="container mt-16">
                    <div className="flex flex-col gap-x-10 md:flex-row">
                      <Testimonial dark></Testimonial>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            }
          ></CrioComparisionSection>
        </RoundedLayout>
      </div>
      <RoundedLayout
        roundedBg="bg-v5-green-100"
        className="bg-white pb-16"
        bottom
      >
        <FAQSection
          data={data.faqs}
          postContent={
            <PostContentCTALayout>
              <LeadGenerationButton
                type="Apply & Start Free"
                buttonLocation={LEAD_GEN_FAQ}
              />
            </PostContentCTALayout>
          }
        ></FAQSection>
      </RoundedLayout>
      <FooterV2></FooterV2>
    </div>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    fspStylePagesYaml(id: { eq: $id }) {
      PrimaryProgramID
      slug
      SEOTitle
      canonicalUrl
      SEODescription

      metatags {
        property
        content
      }

      heroTitle
      heroSubtitle
      heroDescription
      heroImage {
        childImageSharp {
          gatsbyImageData(
            width: 750
            quality: 100
            blurredOptions: {}
            placeholder: NONE
            formats: [AUTO, WEBP]
          )
        }
      }

      statsSubtitle

      highlightsTitle
      highlights {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      highlightsV2 {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      projectsTitle
      projectsDescription
      projectsDescriptionV2

      careerTitle
      careerTitleV2
      careerDescription
      careers {
        description
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      curriculumTitle
      curriculumDescription
      curriculumQuote

      technologies {
        name
        image {
          childImageSharp {
            gatsbyImageData(
              width: 200
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }

      dreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      superDreamCompanies {
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        originalImage {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      curriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        anchor
        cardTitle
        cards {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          data
          skills
          title
        }
      }

      curriculumfsd {
        basicCurriculumFSD {
          description
          duration
          title
          cards {
            description
            skills
            title
            subCards {
              description
              title
              skills
              spanToWidth
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 100
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          subCards {
            description
            skills
            spanToWidth
            title
          }
          specializationType {
            numberOfCards
            title
          }
          description1
          description2
          skills
        }
        advancedCurriculumFSD {
          duration
          cards {
            description
            subCards {
              description
              skills
              spanToWidth
              title
              isCardLarge
            }
            title
            skills
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 100
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          title
          description
        }
        aspiringDeveloper {
          description
          duration
          title
          cards {
            description
            skills
            title
            subCards {
              description
              title
              skills
              spanToWidth
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 100
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          subCards {
            description
            skills
            spanToWidth
            title
          }
          specializationType {
            numberOfCards
            title
          }
          description1
          description2
          skills
        }
        aspiringSeniorDeveloper {
          duration
          cards {
            description
            subCards {
              description
              skills
              spanToWidth
              title
              isCardLarge
            }
            title
            skills
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 100
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          title
          description
        }
      }

      advancedCurriculum {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              width: 100
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        duration
        tag
        data
        skills
        cardTitle
        cards {
          image {
            childImageSharp {
              gatsbyImageData(
                width: 300
                quality: 100
                blurredOptions: {}
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
          data
          skills
          title
        }
      }

      steps

      price
      priceDuration

      faqs {
        question
        answer
      }

      jobGuarantee {
        question
        answer
        image {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 100
              blurredOptions: {}
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }

    allStrapiPrograms {
      ...allStrapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
